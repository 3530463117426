.platform-container {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #f7f9fc;
}

.platform-content {
  background-color: #fff;
  padding: 40px;
  min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.platform-description {
  color: #487790;
}

.platform-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #487790;
}

.platform-content p {
  font-size: 18px;
  margin-bottom: 30px;
  color: #555;
}

.apply-button {
  background-color: #ea5501;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.contact-page {
  padding: 20px;
  text-align: center;
}

.contact-header {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #487790;
}

.contact-description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #487790;
}

.get-support-button {
  background-color: #e34d26;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 40px;
}


.follow-us {
  margin-top: 40px;
}

.follow-header {
  color: #487790;
  margin-right: -96px;
}

.follow-us-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.socialMedia-icons {
  display: flex;
  gap: 40px;
  width: 120px;
}

.socialMedia {
  color: #000;
  transition: color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialMedia:hover {
  color: #007bff;
}

.socialMedia-icons .socialMedia-icon-label {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #555;
}