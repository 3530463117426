/* header.css */

.header {
  width: 100%;
}

.top-bar {
  background-color: black;
  display: flex;
  justify-content: space-between;
  padding: 5.2px;
}

.top_header {
  margin-left: 50px;
  color: #efa524;
  font-size: 14px;
  line-height: 1.4;
}

.social {
  font-size: 8px;
  display: inline-block;
  margin: 0 6px;
  transition: transform 0.05s;
  vertical-align: middle;
}


.social:hover {
  transform: scale(1.1);
}

.youtube {
  color: #c4302b;
}

.twitter {
  color: dodgerblue;
}

.instagram {
  color: #cd486b;
}

.linkedin {
  color: #0e76a8;
}

.right_header {
  margin-right: 50px;
}

.nav-link {
  color: #efa524;
  font-size: 14px;
  margin: 0px 12px;
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
  color: #B63E1E !important;
}

/* Style for disabled Sign In */
.disabled {
  color: grey;
  pointer-events: none;
  cursor: default;
}

/* Dialog Styles */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 26px;
}


.view-apply {
  background-color: #efa524;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.separator {
  border-left: 1px solid #4f4a44;
  display: inline-block;
  position: relative;
}


/* Additional styles for disabled link */
.nav-link.disabled {
  color: grey;
  pointer-events: none;
  cursor: not-allowed;
}


.dialog-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.view-apply {
  background-color: #ea5501;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.separator {
  border-left: 1px solid #4f4a44;
  display: inline-block;
  position: relative;
}

.ConnectUs {
  font-size: 12px;
}

.close-button {
  color: #000000;
  float: right;
  font-size: 26px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .top-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .top_header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .social {
    font-size: 8px;
    margin: 0 8px;
  }

  .right_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }

  .nav-link {
    font-size: 12px;
    margin: 0 8px;
  }

  .separator {
    display: none;
  }
}
