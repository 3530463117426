.news-container {
  width: 85%;
  max-width: 1100px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news-articles {
  width: 100%;
  max-height: 560px;
  overflow-y: auto;
  margin-top: 20px;
}


.news-item {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease; 
}


.news-item:hover {
  transform: translateY(-5px); 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.card-header {
  color: #487790;
font-size: 1.5em;
font-weight: 500;

}


.apply-button {
  padding: 10px 20px;
  background-color: #e34d26;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 95%; 
  height: 80vh;
  border-radius: 8px;
  overflow-y: auto; 
}

.close {
  color: #000000;
  float: right;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}


.news-articles::-webkit-scrollbar-track {
  background-color: #f1f6fa;
}

.news-articles::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.news-articles::-webkit-scrollbar-thumb {
  background-color: #6f96aa;
  border-radius: 6px;
}
