.offerings {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .about-title {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .cardsp-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cardp {
    background-color: #fff;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 1rem;
    width: 300px;
    height: 250px;
    perspective: 1000px;
  }
  
  .cardp-content-front,
  .cardp-content-back {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    transition: transform 0.6s;
  }
  
  .cardp-content-front {
    transform: rotateY(0deg);
  }
  
  .cardp-content-back {
    transform: rotateY(180deg);
  }
  
  .cardp:hover .cardp-content-front {
    transform: rotateY(180deg);
  }
  
  .cardp:hover .cardp-content-back {
    transform: rotateY(0deg);
  }
  
  .cardp-content-front h2 {
    color: #487790;
  }
  
  .cardp-content-back p {
    text-align: center;
    padding: 4px;
    font-size: 1rem;
    line-height: 1.5;
    color: #487790;
  }
  
  .know-more-btn {
    margin-top: 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .know-more-btn:hover {
    background-color: #0056b3;
  }
  