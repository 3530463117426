.contact-page {
    padding: 20px;
    text-align: center;
}

.contact-header {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #487790;
}

.contact-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #487790;
}

.get-support-button {
    background-color: #e34d26;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 40px;
}


.follow-us {
    margin-top: 40px;
}

.follow-header {
    color: #487790;
    margin-right: -96px;
}

.follow-us-header {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.socialMedia-icons {
    display: flex;
    gap: 40px;
    width: 120px;
}

.socialMedia {
    color: #000;
    transition: color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.socialMedia:hover {
    color: #007bff;
}

.socialMedia-icons .socialMedia-icon-label {
    margin-top: 10px;
    font-size: 0.9rem;
    color: #555;
}

.contact-page {
    padding: 20px;
    text-align: center;
}

.contact-header {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #487790;
}

.contact-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #487790;
}

.get-support-button {
    background-color: #e34d26;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 40px;
}


.popover-header {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #487790;
}

.support-textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 10px;
}

.submit-button {
    background-color: #e34d26;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
}