.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  flex-wrap: wrap;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.website-title {
  font-weight: bold;
  font-size: 22px;
  color: #487790;
}

.main-header nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: white;
  border-radius: 5px;
  top: 100%;
}

.dropdown-content a {
  color: #487790;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content,
.dropdown .dropbtn:focus + .dropdown-content {
  display: block;
}

.dropbtn {
  background: none;
  border: none;
  color: #487790;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  line-height: 1;
}

.dropbtn:hover,
.dropbtn:focus {
  color: #b63e1e;
}

.dropdown-content a:hover {
  color: #b63e1e;
}

.dropdown-content a.disabled {
  pointer-events: none; 
  color: #ccc; 
  cursor: not-allowed; 
}

.dropdown-content a.disabled:hover {
  background-color: transparent; 
}


.arrow {
  vertical-align: middle;
  padding-left: 5px;
}

@media (max-width: 768px) {
  .title-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-section {
    justify-content: center;
    width: 100%;
  }

  .main-header nav {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .dropdown {
    width: 100%;
  }

  .dropdown-content {
    position: static;
    width: 100%;
  }

  .dropdown-content a.disabled {
    pointer-events: none; 
    color: #ccc; 
    cursor: not-allowed; 
  }
  
  .dropdown-content a.disabled:hover {
    background-color: transparent; 
  }

  .dropbtn {
    width: 100%;
    text-align: left;
  }
}
