.job-search-container {
  min-height: 350px;
  max-width: 800px;
  margin: auto;
  padding: 50px;
  max-height: 100vh;
}

.job-title {
  color: #487790;
}

.search-input {
  width: 96%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}

.job-listings {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 500px;
  overflow-y: auto;
}

.job-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-card h3 {
  margin-top: 0;
}

.apply-button {
  background-color: #e34d26;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.job-listings::-webkit-scrollbar-track {
  background-color: #f1f6fa;
}

.job-listings::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.job-listings::-webkit-scrollbar-thumb {
  background-color: #6f96aa;
  border-radius: 6px;
}
