.main-landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: clip;
  margin-bottom: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 30%;
  margin-left: 20px;
  margin-bottom: 20px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  transition: 0.6s;
  border-radius: 8px;
  background-color: white;
}

.header-main {
  height: 60vh;
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
}

.header-text {
  width: 70%;
  color: #487790;
  padding-right: 20px;
  padding-left: 100px;
  padding-top: 100px;
}

.about-icon-container {
    text-align: center;
    margin-bottom: 20px;
}

.mission-statement {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}

.get-started {
   padding: 15px 30px;
   font-size: 16px; 
   background-color: #e34d26;
}


.header-image {
  width: 20%;
  margin-top: 25px;
  padding-right: 100px;
}

.card img {
  border-radius: 8px;
}

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.get-started {
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #ea5501;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.cards-container {
  display: flex;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-top: 20px;
}

.about-us {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  text-align: center;
  margin: 20px 0;
  padding-top: 20px;
}

.about-title {
  color: #e34d26;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.about-us p {
  font-size: 16px;
  color: #487790;
  max-width: 800px;
  margin: 10px auto;
}

.offerings {
  /* opacity: 0; */
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  text-align: center;
  margin: 20px 0;
  padding-top: 100px;
}

.offerings h2 {
  font-size: 28px;
  font-weight: bold;
  color: #e34d26;
}

.card-header {
  color: #487790;
  font-size: 20px;
  font-weight: 500;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  height: auto;
  max-height: 200px;
}

.view-opportunities,
.view-news,
.view-apply{
  padding: 10px 20px;
  margin: 20px 0;
  border: none;
  background-color: #ea5501;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

/* Media Queries */
@media (max-width: 768px) {
  .header-main {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .about-us {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    text-align: center;
    margin: 20px 0;
    padding-top: 100px;
  }

  .header-text {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .header-image {
    width: 100%;
    padding-right: 0;
    margin-top: 20px;
  }

  .cards-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .card {
    width: 80%;
    margin-left: 0;
  }

  .about-icon-container img {
    width: 50%;
  }

  .get-started {
    font-size: 14px;
    padding: 10px 20px;
  }

  .title {
    font-size: 24px;
  }

  .mission-statement {
    font-size: 16px;
  }

  .card-header {
    font-size: 18px;
  }

  .about-title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .card {
    margin-left: 0;
    padding: 15px;
  }

  .header-text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about-icon-container img {
    width: 75%;
  }

  .about-us {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    text-align: center;
    margin: 20px 0;
    padding-top: 100px;
  }

  .get-started {
    font-size: 12px;
    padding: 8px 16px;
  }

  .title {
    font-size: 20px;
  }

  .mission-statement {
    font-size: 14px;
  }

  .card-header {
    font-size: 16px;
  }

  .about-title {
    font-size: 20px;
  }
}
